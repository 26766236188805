import { makeAutoObservable, runInAction } from 'mobx';

import { ResponseList } from 'models/Common';
import {
    TransportRequestInsert,
    TransportRequestSearch,
    TransportRequestTag,
    UpdateTransportRequestResult
} from 'data/schemas/TransportRequest';
import {
    getTransportRequestUat,
    insertTransportRequestUat,
    updateTransportRequestUat
} from 'data/repositories/TransportRequest';

import RootStore from '../Root.store';

class TransportRequestStore {
    private _transportRequestResponse: ResponseList<TransportRequestTag[]> | null = null;
    private _updateTransportRequestResult: UpdateTransportRequestResult = {
        commandExecutedOutput: [],
        transportRequestInfo: []
    };

    public get updateTransportRequestResult() {
        return this._updateTransportRequestResult;
    }

    public get transportRequestResponse() {
        return this._transportRequestResponse;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchTransportRequestUat(params?: TransportRequestSearch) {
        try {
            const transportRequestResponse = await getTransportRequestUat(params);

            runInAction(() => {
                this._transportRequestResponse = transportRequestResponse;
            });
        } catch (error) {
            throw error;
        }
    }

    public async insertTransportRequest(params: TransportRequestInsert) {
        try {
            await insertTransportRequestUat(params);
        } catch (error) {
            throw error;
        }
    }

    public async updateTransportRequest(requestBody: { username: string, ports: string, uatTag: string }) {
        try {
            const updateResult = await updateTransportRequestUat({
                Developer: requestBody.username,
                Ports: requestBody.ports,
                Tag: requestBody.uatTag
            });

            runInAction(() => {
                this._updateTransportRequestResult = updateResult;
            });
        } catch (error) {
            throw error;
        }
    }
}

export default TransportRequestStore;
